import React from "react";

function Content() {
  return (
    <div>
      <div className="max-w-4xl m-auto  md:py-24 py-12 px-8">
        <div className="mb-16">
          <div className="mb-8"></div>
          <p>
            "Customer demand for an integrated UCC platform is growing as the
            need for integrated voice, video, and messaging is vital to allow
            people to work together and also to collaborate with customers,"
            stated Jim Lundy, CEO and lead analyst, Aragon Research. "Vonage is
            well-positioned in the UC C landscape to deliver an intelligent
            experience that gives businesses the ability to build an integrated
            solution." Read why Vonage is positioned as a Leader in The Aragon
            Research Globe™ for Unified Communications and Collaboration, 2021 "
          </p>
          <p>
            *June, 2021 - Aragon Research Globe™ for Unified Communications and
            Collaboration
          </p>
        </div>
        <div className="mb-16">
          <div className="mb-8"></div>
          <p>
            "Customer demand for an integrated UCC platform is growing as the
            need for integrated voice, video, and messaging is vital to allow
            people to work together and also to collaborate with customers,"
            stated Jim Lundy, CEO and lead analyst, Aragon Research. "Vonage is
            well-positioned in the UC C landscape to deliver an intelligent
            experience that gives businesses the ability to build an integrated
            solution." Read why Vonage is positioned as a Leader in The Aragon
            Research Globe™ for Unified Communications and Collaboration, 2021 "
          </p>
          <p>
            *June, 2021 - Aragon Research Globe™ for Unified Communications and
            Collaboration
          </p>
        </div>
        <div className="mb-16">
          <div className="mb-8"></div>
          <p>
            "Customer demand for an integrated UCC platform is growing as the
            need for integrated voice, video, and messaging is vital to allow
            people to work together and also to collaborate with customers,"
            stated Jim Lundy, CEO and lead analyst, Aragon Research. "Vonage is
            well-positioned in the UC C landscape to deliver an intelligent
            experience that gives businesses the ability to build an integrated
            solution." Read why Vonage is positioned as a Leader in The Aragon
            Research Globe™ for Unified Communications and Collaboration, 2021 "
          </p>
          <p>
            *June, 2021 - Aragon Research Globe™ for Unified Communications and
            Collaboration
          </p>
        </div>
        <div className="mb-16">
          <div className="mb-8"></div>
          <p>
            "Customer demand for an integrated UCC platform is growing as the
            need for integrated voice, video, and messaging is vital to allow
            people to work together and also to collaborate with customers,"
            stated Jim Lundy, CEO and lead analyst, Aragon Research. "Vonage is
            well-positioned in the UC C landscape to deliver an intelligent
            experience that gives businesses the ability to build an integrated
            solution." Read why Vonage is positioned as a Leader in The Aragon
            Research Globe™ for Unified Communications and Collaboration, 2021 "
          </p>
          <p>
            *June, 2021 - Aragon Research Globe™ for Unified Communications and
            Collaboration
          </p>
        </div>
        <div className="mb-16">
          <div className="mb-8"></div>
          <p>
            "Customer demand for an integrated UCC platform is growing as the
            need for integrated voice, video, and messaging is vital to allow
            people to work together and also to collaborate with customers,"
            stated Jim Lundy, CEO and lead analyst, Aragon Research. "Vonage is
            well-positioned in the UC C landscape to deliver an intelligent
            experience that gives businesses the ability to build an integrated
            solution." Read why Vonage is positioned as a Leader in The Aragon
            Research Globe™ for Unified Communications and Collaboration, 2021 "
          </p>
          <p>
            *June, 2021 - Aragon Research Globe™ for Unified Communications and
            Collaboration
          </p>
        </div>
      </div>
    </div>
  );
}

export default Content;
