import React from "react";
import Content from "./Content";
import Header from "./Header";

function Index({location}) {
  return (
    <div>
      <Header />
      <Content />
    </div>
  );
}

export default Index;
