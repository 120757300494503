import React from "react";

function Header() {
  return (
    <div>
      {" "}
      <div className="header-blue">
        <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
            <div className="text-white">
              <div className="mb-2">
                <span className="font-medium text-xs text-white">
                  {" "}
                  ABOUT US MEDIA COVERAGE
                </span>
              </div>
              <h1 className=" font-medium  lg:text-6xl md:text-5xl text-4xl mb-4 leading-none">
                Media coverage
              </h1>
              <p className="text-2xl mb-5">
                Featured stories of Vonage in the news
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
